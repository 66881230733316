const streamToolsQueue = (() => {
  let pending = Promise.resolve();

  const run = async (cb, params, dispatch, isTerminated) => {
    if (isTerminated) {
      pending = Promise.resolve();
    }
    try {
      await pending;
    } catch (err) {
    } finally {
      const result = await dispatch(cb(...params));
      return result;
    }
  };

  return (cb, params, isTerminated = false) => {
    return async (dispatch) =>
      (pending = run(cb, params, dispatch, isTerminated));
  };
})();

export default streamToolsQueue;
